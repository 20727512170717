import router from '@/router'
import { useConnectionStore } from '@/store/connection'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { createPinia, setMapStoreSuffix } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import VueGtag from 'vue-gtag'
import { createGtm } from 'vue-gtm'
import vueLazysizes from 'vue-lazysizes'
import VueLogger from 'vuejs3-logger'
import App from './App.vue'
import './style.scss'

import Toast, { type PluginOptions } from 'vue-toastification'

import 'vue-toastification/dist/index.css'
import { LogLevels } from 'vuejs3-logger/dist/enum/log-levels'

let bugsnagVue = undefined
if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
  Bugsnag.start({
    apiKey: '0e9a96641ea079d65eb2ab4a74066216',
    plugins: [new BugsnagPluginVue()],
    notifyReleaseStages: [import.meta.env.VITE_BUGSNAG_ENVIROMENT]
  })

  BugsnagPerformance.start({
    apiKey: '0e9a96641ea079d65eb2ab4a74066216',
    releaseStage: import.meta.env.VITE_BUGSNAG_ENVIROMENT
  })
  bugsnagVue = Bugsnag.getPlugin('vue')
}

const isProduction = import.meta.env.VITE_BUGSNAG_ENVIROMENT === 'production' || 'beta'
const logLevel = isProduction ? LogLevels.DEBUG : LogLevels.ERROR
const options = {
  isEnabled: true,
  logLevel: logLevel,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

// Set options for plugins.
const toastOptions: PluginOptions = {
  maxToasts: 5,
  newestOnTop: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  hideProgressBar: false,
  closeButton: false,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  filterBeforeCreate: (toast: any, toasts: any) => {
    if (toasts.filter((t: any) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast.
      return false
    }
    return toast
  }
}

setMapStoreSuffix('')
const storePinia = createPinia()
storePinia.use(piniaPluginPersistedstate)

createApp(App)
  .use(router)
  .use(storePinia)
  .use(Toast, toastOptions)
  .use(VueLogger, options)
  .use(VueGtag, {
    bootstrap: false,
    appName: 'Y.Pony',
    pageTrackerEnabled: true,
    pageTrackerScreenviewEnabled: true,
    // pageTrackerTemplate(to: { path: string, name: string }) {
    //   return {
    //     page_title: to.name,
    //     page_path: to.path
    //   }
    // },
    config: {
      id: import.meta.env.VITE_VUE_APP_GOOGLE_ANALYTICS_ID,
      params: {
        transport_type: 'beacon'
      }
    }
  })
  .use(
    createGtm({
      id: import.meta.env.VITE_VUE_APP_GTM_ID,
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: '2726c7f26c', // Will add `nonce` to the script tag
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true
    })
  )
  .directive('lazysizes', vueLazysizes)
  .use(bugsnagVue)
  .mount('#app')

const connectionStore = useConnectionStore()

connectionStore.disconnect()

connectionStore.bindEvents()
