<template>
  <div>
    <div class="profile-gallery">
      <swiper
        :slides-per-view="'auto'"
        :centered-slides="true"
        :space-between="14"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        class="mySwiper single-image"
      >
        <swiper-slide>
          <c-image
            v-if="
              portfolio.attributes.payload.length &&
                gExtension(portfolio.attributes.payload[0].filename).type ===
                'img'
            "
            :image-url="portfolio.attributes.payload[0].slideshow_16x9"
            :alt="portfolio.attributes.account?.company"
            class="slide-img"
            :portfolio-id="portfolio.id?.toString()"
          />
          <c-video
            v-else-if="
              portfolio.attributes.payload.length &&
                gExtension(portfolio.attributes.payload[0].filename).type ===
                'video'
            "
            :payload="portfolio.attributes.payload"
            class="slide-img"
          />
        </swiper-slide>
      </swiper>
    </div>

    <div class="tags">
      <ul
        class="list-horizontal d-flex flex-wrap justify-content-center column-gap-2"
      >
        <li
          v-for="tag in buildTags(portfolio.attributes)"
          :key="tag"
        >
          <i>{{ tag }}</i>
        </li>
      </ul>
    </div>

    <div class="my-4">
      <p class="font-red">
        <i>
          <strong>Get in touch!</strong>
        </i>
      </p>
    </div>

    <div>
      <router-link
        :to="{
          query: {
            account_id: portfolio.attributes.account?.account_id,
          },
          path: `/${createSlug(portfolio.attributes.account?.company)}/me`,
        }"
        class="btn btn-primary my-3"
      >
        View Profile
      </router-link>

      <router-link
        v-if="false"
        :to="{
          query: { portfolio_id: portfolio.id },
          path: `/${createSlug(portfolio.attributes.account?.company)}/me`,
        }"
        class="btn btn-primary my-3"
      >
        Add to favorites
      </router-link>
    </div>

    <div class="d-flex justify-content-center my-5">
      <img
        :srcset="logoDot"
        :src="logoDot"
        class="divider"
        alt="Section divider"
      >
    </div>

    <div>
      <h5>Others also viewed this</h5>
    </div>

    <ul
      v-if="portfolios"
      class="portfolio"
    >
      <router-link
        v-for="portfolio in portfolios"
        :key="portfolio.id"
        :to="{
          path: `/${createSlug(portfolio.attributes.account?.company)}/${
            portfolio.id
          }/style`,
        }"
      >
        <li class="shadow">
          <c-image
            v-if="
              portfolio.attributes.payload.length &&
                gExtension(portfolio.attributes.payload[0].filename).type ===
                'img'
            "
            :image-url="portfolio.attributes.payload[0].slideshow_16x9"
            :alt="portfolio.attributes.account?.company"
            class="slide-img"
            :portfolio-id="portfolio.id?.toString()"
          />
          <c-video
            v-else-if="
              portfolio.attributes.payload.length &&
                gExtension(portfolio.attributes.payload[0].filename).type ===
                'video'
            "
            :payload="portfolio.attributes.payload"
          />
          <span class="visibility-hidden">Product Title</span>
        </li>
      </router-link>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { buildTags, createSlug, getExtension, logoDot } from '@/helpers'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
// import required modules
import { CImage, CVideo } from '@/components'
import type { PortfoliosType, PortfolioType } from '@/types'
import { EffectCards, Pagination } from 'swiper'
import 'swiper/css/effect-cards'
import { computed, type PropType } from 'vue'
import { useAccountStore } from '../store/accounts/index'

const accountStore = useAccountStore()
defineProps({
  portfolio: {
    type: Object as PropType<PortfolioType>,
    required: true,
  },
  portfolios: {
    type: Object as PropType<PortfoliosType>,
    required: true,
  },
})

const gExtension = (file: string) => {
  return getExtension(file)
}

const current_account = computed(() => accountStore.current_account)

const pagination = {
  clickable: true,
  renderBullet: (index: number, className: string) =>
    `<span class="${className}"></span>`,
}
const modules = [EffectCards, Pagination]
</script>

<style>
</style>
