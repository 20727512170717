<template>
  <div v-if="isRequestLoading" class="loading-state" />
  <div v-else-if="!isRequestLoading && portfolios.length < 1">
    <h1 v-if="!isUserLoggedOut" class="font-wht">You have no Style yet!</h1>
    <h1 v-else class="font-wht">There is no style for the current slideshow!</h1>
    <router-link
      v-if="currentAccount?.attributes?.account_id && !isUserLoggedOut"
      :to="{
        name: routesNames.addOrEditStyle,
        params: {
          account_id: currentAccount.attributes.account_id,
        },
      }"
    >
      <IButton :tab-index="1" class="btn btn-primary"> Add a Style </IButton>
    </router-link>
  </div>
  <div v-else>
    <swiper
      :style="styleObject"
      :loop="true"
      :space-between="10"
      :navigation="true"
      :centered-slides="true"
      :autoplay="{
        delay: 10000,
        disableOnInteraction: false,
      }"
      :modules="modules"
      class="mySwiper2"
      component="slideshow"
      :thumbs="{ swiper: thumbsSwiper }"
    >
      <swiper-slide v-for="portfolio in portfolios" :key="portfolio.id">
        <template v-if="portfolio.attributes.payload.length === 2">
          <c-video
            v-if="
              portfolio.attributes.payload.length &&
              gExtension(portfolio.attributes.payload[0].filename).type === 'video'
            "
            class="slide-img"
            :payload="portfolio.attributes.payload"
          />
        </template>
        <template v-else>
          <c-image-slides
            v-if="
              portfolio.attributes.payload.length &&
              gExtension(portfolio.attributes.payload[0].filename).type === 'img'
            "
            :image-url="portfolio.attributes.payload[0].slideshow_16x9"
            :alt="portfolio.attributes.payload[0].filename"
            :portfolio-id="portfolio.id?.toString()"
          />
          <c-video
            v-else-if="
              portfolio.attributes.payload.length &&
              gExtension(portfolio.attributes.payload[0].filename).type === 'video'
            "
            class="slide-img"
            :payload="portfolio.attributes.payload"
          />
        </template>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import { CImageSlides, CVideo, Button as IButton } from '@/components'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref } from 'vue'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import { getExtension, slideShowImageKey } from '@/helpers'
import { routesNames } from '@/router'
import { socket } from '@/socket'
import { useConnectionStore } from '@/store/connection'
import { useCookieBannerStore } from '@/store/CookieBannerStore'
import { usePortfoliosSlideshowStore } from '@/store/portfolios/portfolios_slideshow'
import type { PortfolioType } from '@/types'
import { A11y, Autoplay, Navigation, Thumbs } from 'swiper'
import { UAParser } from 'ua-parser-js'
import type { AccountType } from '../types/index'

export default {
  components: {
    Swiper,
    SwiperSlide,
    IButton,
    CImageSlides,
    CVideo,
  },
  props: {
    portfolios: {
      type: Array<PortfolioType>,
      default: () => [],
    },
    isUserLoggedOut: {
      type: Boolean,
      default: false,
    },
    isRequestLoading: {
      type: Boolean,
      default: true,
    },
    slideShowWidth: {
      type: Number,
      default: 0,
    },
    slideShowHeight: {
      type: Number,
      default: 0,
    },
    currentAccount: {
      type: Object,
      default: (): AccountType => ({
        id: '',
        type: '',
        attributes: {
          avatar: '',
          first_name: '',
          last_name: '',
          company: '',
          job_title: '',
          city: '',
          about_me: '',
          price_min: 0,
          price_max: 0,
          zip_code: 0,
          tags: [],
          abilities: [],
          invited_user_count: 0,
          state: '',
          fullname: '',
          account_id: '',
          status: 0,
          portfolio_count: 0,
          email: '',
          facebook: '',
          whatsapp: '',
          telegram: '',
          address: '',
          domain: '',
          telephone: '',
          instagram: '',
          referral_code: '',
          booking_link: '',
        },
      }),
    },
  },
  setup() {
    const thumbsSwiper = ref()
    const setThumbsSwiper = (swiper: unknown): void => {
      thumbsSwiper.value = swiper
    }

    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
      slideShowImageKey,
      routesNames,
      styleObject: {
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      },
      modules: [Navigation, Thumbs, A11y, Autoplay],
    }
  },
  created() {
    const connectionStore = useConnectionStore()
    connectionStore.connect()

    const account_id = window.location.pathname.split('-').pop()

    const date = new Date();
    const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds());
    const currTimestamp = new Date(now_utc)


    socket.on('connection_id', (data) => {
      const parser = new UAParser();
      if (this.portfolios.length > 0) {
       socket.emit('slideshow_online', { account_id, status: 'online', online_session: currTimestamp, connection_id: socket.id, browser_info: parser.getResult(), user_ip: useCookieBannerStore().user_ip });
      }
    })

    socket.on("UPDATE:SLIDESHOW", (message) => {
      const parsedMessage = JSON.parse(message)
      const portfolioSlideshowStore = usePortfoliosSlideshowStore()

      if (portfolioSlideshowStore.current_account.attributes.account_id === parsedMessage.account_id) {
        portfolioSlideshowStore.$reset()
        portfolioSlideshowStore.GET_CURRENT_ACCOUNT({
          account_id: parsedMessage.account_id
        })
        portfolioSlideshowStore.GET_CURRENT_ACCOUNT_PORTFOLIOS({
          account_id: parsedMessage.account_id
        })
      }
    });
  },
  methods: {
    gExtension(file: string | File) {
      return getExtension(file)
    },
  },
}
</script>

<style lang="scss">
.is-ready {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: #fff;
}
.is-loader {
  margin: 2px;
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #fff 90%, #0000) 0 / calc(100% / 3)
    100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
.slideshow {
  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .mySwiper2 {
    /* Main Slider */
    // height: calc(100vh - 130px - 150px);
    // width: 100%;
  }

  .swiper-thumbs {
    /* Image Caroussel */
    display: block;
    // height: 20%;
    box-sizing: border-box;
    padding: 2vh 0;

    .swiper-slide {
      width: 25%;
      height: 120px;
      opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  .swiper-slide {
    // border-radius: 1em;
    overflow: hidden;
    background-size: cover;
    background-position: center;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper:hover {
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
      display: block;
    }
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev,
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    display: none;
  }
}
</style>
