<template>
  <div>
    <ul class="d-flex column-gap-3 rounded-1 portfolio-gallery-multi px-3 py-4">
      <li
        v-for="image in newPreviews()"
        :key="image.id"
        class="portfolio-gallery-container"
      >
        <div class="shadow init box">
          <c-image
            v-if="
              getExtension(image.filename).type === 'img' &&
                typeof image.file !== 'object'
            "
            :image-url="image.preview"
            :alt="image.filename"
          />
          <c-video
            v-else-if="getExtension(image.file).type === 'video'"
            class="slide-img"
            :content-type="getExtension(image.file).mineType.toString()"
            :video-url="image.preview"
          />
          <c-image
            v-else
            :image-url="image.preview"
            :alt="image.filename"
          />
        </div>
        <div
          class="d-flex justify-content-between column-gap-3 mb-2 mt-2 justify-content-center mt-1 align-items-baseline"
        >
          <label
            :for="'init_' + image.id"
            title="Upload image"
          >
            <input
              :id="'init_' + image.id"
              type="file"
              :name="image.name"
              autocomplete="off"
              class="hidden"
              @change="attachImage($event, image.id)"
            >

            <img
              src="@/assets/btn-edit.png"
              srcset="@/assets/btn-edit@2x.png 2x"
              alt="btn-edit"
            >
            <progress
              v-if="isConverting"
              :id="'init_' + image.id"
              class="uploader d-flex align-items-baseline m-2"
              :value="progressValue"
              max="100"
            />
          </label>
        </div>
        <small class="hint">Videos of max. 10s or 25mb</small>
      </li>
    </ul>
  </div>
</template>

<script>
import CImage from '@/components/CImage.vue';
import CVideo from '@/components/CVideo.vue';
import {
  fileNameExist,
  fileSizeCheck,
  getExtension,
  preProcessImageFile,
  renderImages,
  toJSON
} from '@/helpers';
import { usePortfoliosStore } from '@/store/portfolios';
import { useFFmpeg } from '@/utils/useFFmpeg';

export default {
  name: 'CreateMultiUpload',
  components: { CVideo, CImage },
  emits: ['isConverting'],
  data() {
    return {
      getExtension: getExtension,
      toJSON: toJSON,
      image_disabled: 1,
      progressValue: 0,
      isConverting: false,
    }
  },
  methods: {
    portfoliosStore: () => usePortfoliosStore(),
    attachImage(event, id) {
      const file = event.target.files[0]

      if (
        fileNameExist(file, this.portfoliosStore().getCurrentPortfolioPayload)
      )
        return

      if (fileSizeCheck(file) === false) return

      this.create_or_update_multi_image(file, id)
    },

    async create_or_update_multi_image(file, id) {
      const imageURL = await preProcessImageFile(file, this.$emit)
      const {convertToGIF,output, isConverting, ffmpeg, fileToUpload} = useFFmpeg()
      ffmpeg.on("progress", ({ progress, _time }) => {this.progressValue = progress * 100 * 1})
      let newFile = imageURL.fileToUpload

      if (!imageURL) {
        this.$emit('isConverting',isConverting.value)
        this.isConverting= isConverting.value

        await convertToGIF(file)

        this.$emit('isConverting',isConverting.value)
        this.isConverting= isConverting.value
        newFile = fileToUpload.value
      }

      const {
        image_to_delete,
        hidden_in_portfolio,
        show_in_slideshow,
        image_disabled,
        published,
      } = this.updateImageToDelete(id)
      this.portfoliosStore().UPDATE_PORTFOLIO_MULTI_IMAGE({
        preview: imageURL.preview || output.value,
        file: newFile,
        id: id,
        hidden_in_portfolio: hidden_in_portfolio,
        show_in_slideshow: show_in_slideshow,
        image_to_delete: image_to_delete,
        image_disabled: image_disabled,
        published: published,
        type: file.type,
      })
    },
    updateImageToDelete(id) {
      const portfoliosStore = usePortfoliosStore()
      const portfolio = portfoliosStore.getPortfolioPayloadByID(id)

      return {
        image_to_delete: typeof portfolio.file === 'string',
        hidden_in_portfolio: portfolio.hidden_in_portfolio,
        show_in_slideshow: portfolio.show_in_slideshow,
        published: portfolio.published,
      }
    },
    showInSlideshow() {
      const payload = this.portfoliosStore().getCurrentPortfolioPayload.filter(
        (payload) => {
          return toJSON(payload.show_in_slideshow) === true
        }
      )

      return payload
    },
    newPreviews() {
      const payload = this.portfoliosStore().getCurrentPortfolioPayload
      if (payload.length === 0) {
        const renderImage = renderImages(1)
        this.portfoliosStore().addPortfolioState({ payload: renderImage[0] })
      }

      const firstObj = payload[0]
      firstObj.show_in_slideshow = true
      payload[0] = firstObj
      return payload
    },
  },
}
</script>

<style scoped>
.hidden {
  display: none;
}

.img-text {
  all: unset;
}
</style>
