import ErrorMessage from '@/ErrorMessage'
import { AccountDataService as Account } from '@/services'
import type { AccountType, ApiResponse } from '@/types/index'
import Bugsnag from '@bugsnag/js'
import { pick } from 'lodash'
import { defineStore } from 'pinia'
import { ActionTypes } from '../action-types'
import { useErrorStore } from '../errorStore'
import { state as initialState } from '../state'
const state = pick(initialState, [
  'portfolios',
  'status',
  'statusText',
  'isRequestLoading',
  'isLoading',
  'current_account',
])

export const usePortfoliosSlideshowStore = defineStore('portfoliosSlideshowStore', {
  state: () => ({ ...state }),
  getters: {
    getCurrentAccount({ current_account }): AccountType {
      return current_account
    },
  },
  actions: {
    async [ActionTypes.GET_CURRENT_ACCOUNT]({ account_id }: { account_id: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const account = Account.getAccountByID(account_id)

        resolve(account)
      })

      try {
        const {
          data: { data: account },
          status,
          statusText,
        } = await promise
        this.current_account = account
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
        if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
          const errorMessage = new ErrorMessage(ActionTypes.GET_CURRENT_ACCOUNT, error)
          Bugsnag.notify(errorMessage)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
    async [ActionTypes.GET_CURRENT_ACCOUNT_PORTFOLIOS]({ account_id }: { account_id: string }) {
      this.isLoading = true
      const promise: Promise<ApiResponse> = new Promise((resolve) => {
        const portfolios = Account.getAccountPortfolios(account_id)

        resolve(portfolios)
      })

      try {
        const {
          data: { data: portfolios },
          status,
          statusText,
        } = await promise
        this.portfolios = portfolios
        this.status = Number(status)
        this.statusText = statusText
      } catch (error) {
        if (import.meta.env.VITE_BUGSNAG_ENVIROMENT) {
          const errorMessage = new ErrorMessage(ActionTypes.GET_CURRENT_ACCOUNT_PORTFOLIOS, error)
          Bugsnag.notify(errorMessage)
        }
        useErrorStore().UPDATE_ERROR(error)
      } finally {
        if (useErrorStore().errors.status !== 404) {
          this.isRequestLoading = false
          this.isLoading = false
        }
      }
    },
  },
  persist: true,
})
