<template>
  <main v-if="currentAccount">
    <div class="profile-gallery">
      <ul class="single-image">
        <li v-if="currentAccount.attributes.avatar" class="shadow">
          <c-image
            :image-url="currentAccount.attributes.avatar"
            :alt="currentAccount.attributes.company"
          />
          <span class="visibility-hidden">Product Title</span>
        </li>
      </ul>
    </div>
    <div>
      <p class="mb-1" title="Company">
        <em> {{ currentAccount.attributes.company }} </em>
      </p>
      <h5 class="my-0">
        {{ currentAccount.attributes.first_name }}
        {{ currentAccount.attributes.last_name }}
      </h5>
      <p class="mt-1" title="Job description">
        <em> {{ currentAccount.attributes.job_title }} </em>
      </p>
      <p class="mb-1" title="Address">
        <em> {{ currentAccount.attributes.address }} </em>
      </p>
    </div>

    <div class="my-4">
      <p class="font-orange">
        <em>
          {{ currentAccount.attributes.about_me }}
        </em>
      </p>
      <p class="font-orange">
        <em>
          My pricing range: {{ currentAccount.attributes.price_min }}-{{
            currentAccount.attributes.price_max
          }}
          €
        </em>
      </p>
    </div>
    <div>
      <a
        v-if="currentAccount.attributes.instagram"
        :href="
          'https://www.instagram.com/' + currentAccount.attributes.instagram
        "
        class="btn btn-primary my-3"
      >
        Instagram
      </a>
      <a
        v-if="currentAccount.attributes.whatsapp"
        :href="
          'https://api.whatsapp.com/send?phone=' +
          currentAccount.attributes.whatsapp
        "
        class="btn btn-primary my-3"
      >
        Whatsapp
      </a>
      <a
        v-if="currentAccount.attributes.facebook"
        :href="'https://www.facebook.com/' + currentAccount.attributes.facebook"
        class="btn btn-primary my-3"
      >
        Facebook
      </a>
      <a
        v-if="currentAccount.attributes.telegram"
        :href="'https://telegram.me/' + currentAccount.attributes.telegram"
        class="btn btn-primary my-3"
      >
        Telegram
      </a>
      <a
        v-if="currentAccount.attributes.domain"
        :href="currentAccount.attributes.domain"
        class="btn btn-primary my-3"
      >
        Homepage
      </a>
      <a
        v-if="currentAccount.attributes.booking_link"
        :href="currentAccount.attributes.booking_link"
        class="btn btn-primary my-3"
      >
        Book Me!
      </a>
    </div>

    <div class="d-flex justify-content-center my-5">
      <img
        :srcset="logoDot"
        :src="divider"
        class="divider"
        alt="Section Divider"
      />
    </div>

    <div>
      <h6>This is my portfolio</h6>
    </div>
    <ul v-if="portfolios.length" class="portfolio">
      <router-link
        v-for="(portfolio, index) in portfolios"
        :key="index"
        :to="{
          path: `/${createSlug(currentAccount.attributes.company)}/${
            portfolio.id
          }/style`,
        }"
      >
        <li class="shadow">
          <c-image
            v-if="
              portfolio.attributes.payload.length &&
              gExtension(portfolio.attributes.payload[0].filename).type ===
                'img'
            "
            :image-url="portfolio.attributes.payload[0].slideshow_16x9"
            :alt="portfolio.attributes.account?.company"
          />
          <c-video
            v-else-if="
              portfolio.attributes.payload.length &&
              gExtension(portfolio.attributes.payload[0].filename).type ===
                'video'
            "
            :payload="portfolio.attributes.payload"
          />

          <span class="visibility-hidden">Product Title</span>
        </li>
      </router-link>
    </ul>
  </main>
</template>

<script lang="ts" setup>
import { createSlug, divider, getExtension, logoDot } from '@/helpers'
import type { AccountType, PortfoliosType } from '@/types'

import { CImage, CVideo } from '@/components'
import type { PropType } from 'vue'

defineProps({
  currentAccount: {
    type: Object as PropType<AccountType>,
    required: true,
  },
  portfolios: {
    type: Object as PropType<PortfoliosType>,
    required: true,
  },
})

const gExtension = (file: string) => {
  return getExtension(file)
}
</script>

<style></style>
