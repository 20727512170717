import { state as initialState } from '@/store/state'
import { pick } from 'lodash'
import { defineStore } from 'pinia'
const state = pick(initialState, ['selected_tab', 'selected_sub_tab'])

export const useSelectTabStore = defineStore('selectTabStore', {
  state: () => ({ ...state }),
  getters: {
    getSelectedTab ({ selected_tab }) {
      return selected_tab
    },
    getSelectedSubTab ({ selected_sub_tab }) {
      return selected_sub_tab
    }
  },

  actions: {
    updateSelectedTab (selected: number) {
      this.selected_tab = selected
    },
    updateSelectedSubTab (selected: number) {
      this.selected_sub_tab = selected
    }
  },
  persist: {
    storage: sessionStorage
  }
})
