<template>
  <div v-if="actionType == 'create'">
    <CreateMultiUpload @is-converting="$emit('isConverting', $event)" />
  </div>

  <div v-else>
    <UpdateMultiUpload @is-converting="$emit('isConverting', $event)" />
  </div>
</template>
<script>
import CreateMultiUpload from './multi-upload/createMulti.vue';
import UpdateMultiUpload from './multi-upload/updateMulti.vue';

export default {
  name: 'MultiUpload',
  components: { CreateMultiUpload, UpdateMultiUpload },
  props: {
    actionType: {
      type: String,
      default: 'create',
    },
  },
  emits: ['isConverting'],
}
</script>
