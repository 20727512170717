import router from '@/router'
import axios, { type AxiosError, type AxiosInstance, type AxiosRequestConfig } from 'axios'
import { isExpireAtLessThenNow, verifyJWT } from './helpers'
import { TokenDataService } from './services'
import { useAuthTokenStore } from './store/authToken'
import type { JWTType, UserType } from './types'
import { useResetStore } from './utils/useResetStore'

const base_url = String(import.meta.env.VITE_VUE_APP_API_BASE_URL)
const http_apikey = import.meta.env.VITE_VUE_APP_TOKEN_SIGNING_SECRET || 'secret-string'

export function isAxiosError<ResponseType> (error: unknown): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error)
}

// Create a new axios instance for Auth/v1 requests.
export const authClient: AxiosInstance = axios.create({
  baseURL: base_url,
  headers: {
    HTTP_APIKEY: http_apikey
    // Accept: 'application/json',
    // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': import.meta.env.VITE_VUE_APP_API_BASE_URL,
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    // 'Access-Control-Allow-Headers': '*',
    // 'Access-Control-Expose-Headers': '*',
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Expose-Headers': ['Access-Token', 'Refresh-Token', 'Access-Token', 'Uid'],
    // HTTP_APIKEY: http_apikey,
    // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': import.meta.env.VITE_VUE_APP_API_BASE_URL,
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    // 'Access-Control-Expose-Headers': 'Origin, Content-Type, X-Auth-Token',
    // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    // 'Access-Control-Allow-Credentials': 'true',
    // 'Access-Control-Max-Age': '3600',
  },
  timeout: 18000, // 5 second
  withCredentials: true,
  responseType: 'json'
})

// Create a new axios instance for Postmark API requests.
export const postmarkClient: AxiosInstance = axios.create({
  baseURL: 'https://api.postmarkapp.com',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Postmark-Server-Token': String(import.meta.env.VITE_VUE_APP_POSTMARK_SERVER_TOKEN)
  },
  timeout: 30000 // 5 second
})

// authClient.defaults.withCredentials = true

authClient.interceptors.request.use(
  async config => {
    const authTokenStore = useAuthTokenStore()
    config.withCredentials = true
    if (config.headers) {
      config.headers.Authorization = `Bearer ${authTokenStore.jwt.access_token}`
      config.headers['Refresh-Token'] = `${authTokenStore.jwt.refresh_token}`
    }
    return config
  },
  error => {
    return Promise.reject(new Error(error))
  }
)

// Flag to prevent multiple token refresh requests
let isRefreshing = false

authClient.interceptors.response.use(
  response => {
    const authTokenStore = useAuthTokenStore()
    if (response.headers['access-token']) {
      authTokenStore.updateJWT({} as JWTType)
      const newJWT = {
        access_token: response.headers['access-token'],
        refresh_token: response.headers['refresh-token'],
        expire_at: response.headers['expire-at']
      }
      const decoded = verifyJWT(newJWT.access_token as string)
      authTokenStore.$patch(
        (state: {
          jwt: JWTType
          current_user: UserType
          authToken: boolean
          isUserLoggedOut: boolean
        }) => {
          state.jwt = newJWT as JWTType
          state.current_user = decoded
          state.authToken = !isExpireAtLessThenNow(newJWT.expire_at)
          state.isUserLoggedOut = isExpireAtLessThenNow(newJWT.expire_at)
        }
      )
    }
    return response
  },
  async error => {
    const originalRequest: AxiosRequestConfig = error.config
    if (
      error.response &&
      error.response.status === 401 &&
      useAuthTokenStore().jwt.refresh_token &&
      error.response.data.error === 'Access token expired'
    ) {
      if (!isRefreshing) {
        isRefreshing = true
        try {
          // Refresh the access token
          const { headers } = await TokenDataService.renew()

          // Update the request headers with the new access token
          error.config.headers.Authorization = `Bearer ${headers['access-token']}`

          // Retry the original request
          return authClient(originalRequest)
        } catch (refreshError) {
          useResetStore().all()
          useResetStore().errorStore()
          useAuthTokenStore().$reset()
          useAuthTokenStore().updateJWT({} as JWTType)

          router.push('/')
        } finally {
          isRefreshing = false
        }
      }
    } else if (error?.response?.data?.statusText === 'Invalid access token') {
      router.push('/login')
    }

    // Return a Promise rejection if the status code is not 401
    return Promise.reject(error)
  }
)
