import algoliasearch from 'algoliasearch/lite'

/**
 * @class SearchClientService
 * @description Algolia search client service.
 */
class SearchClientService {
  searchClient() {
    const appId = import.meta.env.VITE_VUE_APP_ALGOLIA_APP_ID
    const apiKey = import.meta.env.VITE_VUE_APP_ALGOLIA_API_KEY
    return algoliasearch(appId, apiKey)
  }
}

export default new SearchClientService()
