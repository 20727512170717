import { authClient } from '@/requests'
import type { ApiResponse } from '@/types'

/**
 * @class UserLogoutDataService
 * @description Axios service provider for make API requests to UserLogout model.
 */
class UserLogoutDataService {
  /**
   * @method logout
   * @description Method to de-authenticate user.
   * @param {String} token
   * @returns Promise
   */
  logout (): Promise<ApiResponse> {
    return authClient.delete('/v1/users/sign_out')
  }
}

// Export UserLogout data service.
export default new UserLogoutDataService()
