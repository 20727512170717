import { socket } from "@/socket";
import { defineStore } from "pinia";

export const useConnectionStore = defineStore("ConnectionStore", {
  state: () => ({
    isConnected: false,
    socketID: '',
  }),

  actions: {
    bindEvents() {
      socket.on("connect", () => {
        console.log('useConnectionStore: connect')
        this.isConnected = true;
        this.socketID = socket.id;
      });

      socket.on("disconnect", () => {
        console.log('useConnectionStore: disconnect')
        this.isConnected = false;
        this.socketID = socket.id;
      });
    },

    connect() {
      socket.connect();
    },
    disconnect() {
      socket.off();
    },
    getSocketId() {
      return socket.id;
    }
  },
});
