<template>
  <div v-if="portfoliosStore().isRequestLoading" />
  <div
    v-else
    class=""
  >
    <ul class="d-flex column-gap-3 rounded-1 portfolio-gallery-multi">
      <li
        v-for="image in showInSlideshow()"
        :key="image.id"
        class="portfolio-gallery-container"
      >
        <div class="shadow init box">
          <c-image
            v-if="
              getExtension(image.filename).type === 'img' &&
                typeof image.file !== 'object'
            "
            :image-url="image.slideshow_16x9"
            :alt="image.filename"
          />
          <c-video
            v-else-if="
              !image.preview &&
                image.file &&
                getExtension(image.filename).type === 'video'
            "
            :content-type="getExtension(image.filename).mineType"
            :video-url="image.file"
          />
          <c-video
            v-else-if="
              image.preview && getExtension(image.file).type === 'video'
            "
            class="slide-img"
            :content-type="getExtension(image.file).mineType"
            :video-url="image.preview"
          />
          <img
            v-else
            :src="image.preview"
          >
        </div>

        <div
          class="d-flex justify-content-between column-gap-3 mb-2 mt-2 justify-content-center mt-1 align-items-baseline"
        >
          <label
            :for="'init_' + image.id"
            title="Upload image"
          >
            <input
              :id="'init_' + image.id"
              type="file"
              :name="image.name"
              autocomplete="off"
              class="hidden"
              @change="attachimage($event, image.id)"
            >
            <img
              src="@/assets/btn-edit.png"
              srcset="@/assets/btn-edit@2x.png 2x"
              :alt="image.name"
            >
            <progress
              v-if="isConverting"
              :id="'init_' + image.id"
              class="uploader d-flex align-items-baseline m-2"
              :value="progressValue"
              max="100"
            />
          </label>
        </div>
        <small class="hint">Videos of max. 10s or 25mb</small>
      </li>
    </ul>
  </div>
</template>
<script>
import CImage from '@/components/CImage.vue'
import CVideo from '@/components/CVideo.vue'
import {
  fileNameExist,
  fileSizeCheck,
  getExtension,
  preProcessImageFile,
  toJSON,
} from '@/helpers'
import { usePortfoliosStore } from '@/store/portfolios'

import { useFFmpeg } from '@/utils/useFFmpeg'
import { useResetStore } from '../../../utils/useResetStore'
export default {
  name: 'UpdateMultiUpload',
  components: {
    CImage,
    CVideo,
  },
  emits: ['isConverting'],
  data() {
    return {
      getExtension: getExtension,
      toJSON: toJSON,
      image_disabled: 0,
      progressValue: 0,
      isConverting: false
    }
  },
  unmounted() {
    useResetStore().portfoliosStore()
    usePortfoliosStore().payload = []
  },
  methods: {
    portfoliosStore: () => usePortfoliosStore(),
    handleShowInGallery(prev) {
      this.portfoliosStore().UPDATE_PORTFOLIO_MULTI_IMAGE({
        id: prev.id,
        hidden_in_portfolio: prev.hidden_in_portfolio,
        show_in_slideshow: prev.show_in_slideshow,
      })
      if (toJSON(prev.show_in_slideshow)) this.image_disabled = prev.id
      else this.image_disabled = 0
    },
    attachimage(event, id) {
      const file = event.target.files[0]

      if (
        fileNameExist(file, this.portfoliosStore().getCurrentPortfolioPayload)
      )
        return
      if (fileSizeCheck(file) === false) return

      this.create_or_update_multi_or_image(file, id)
    },
    async create_or_update_multi_or_image(file, id) {
      const imageURL = await preProcessImageFile(file, this.$emit)
      const {convertToGIF, output, isConverting, ffmpeg, fileToUpload} = useFFmpeg()
      ffmpeg.on("progress", ({ progress, time }) => {this.progressValue=progress * 100 * 1})

      let newFile =  imageURL.fileToUpload

      if (!imageURL) {
        this.$emit('isConverting',isConverting.value)
        this.isConverting= isConverting.value

        await convertToGIF(file)

        this.$emit('isConverting',isConverting.value)
        this.isConverting= isConverting.value
        newFile = fileToUpload.value
      }

      const {
        image_to_delete,
        hidden_in_portfolio,
        show_in_slideshow,
        image_disabled,
        image_key,
        published,
      } = this.updateImageToDelete(id)
      this.portfoliosStore().UPDATE_PORTFOLIO_MULTI_IMAGE({
        preview: imageURL.preview || output.value,
        file: newFile,
        id: id,
        hidden_in_portfolio: hidden_in_portfolio,
        image_to_delete: image_to_delete,
        show_in_slideshow: show_in_slideshow,
        image_disabled: image_disabled,
        image_key: image_key,
        published: published,
        type: file.type,
      })
    },
    showInSlideshow() {
      const payload = this.portfoliosStore().getCurrentPortfolioPayload
      if (payload.length ===2) {
       return payload.filter(
          (payload) => {
            return payload.content_type === "video/mp4"
          }
        )

      } else {
        return payload
      }
    },
    updateImageToDelete(id) {
      const portfolio = this.portfoliosStore().getPortfolioPayloadByID(id)

      return {
        image_to_delete: typeof portfolio.file === 'string',
        hidden_in_portfolio: portfolio.hidden_in_portfolio,
        show_in_slideshow: portfolio.show_in_slideshow,
        image_key: portfolio.image_key,
        published: portfolio.published,
      }
    },
    newPreviews() {
      return this.portfoliosStore().getCurrentPortfolioPayload
    },
  },
}
</script>
<style scoped>
.hidden {
  display: none;
}
.img-text {
  all: unset;
}
</style>
