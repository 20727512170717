import { ActionTypes } from '@/store/action-types'
import { state as initialState } from '@/store/state'
import { defineStore } from 'pinia'

import { pick } from 'lodash'
const state = pick(initialState, ['errors'])

export const useErrorStore = defineStore('errorStore', {
  state: () => ({ ...state }),
  getters: {
    getError ({ errors }) {
      return errors
    }
  },
  actions: {
    [ActionTypes.UPDATE_ERROR] (payload: any) {
      this.errors = payload.response ? payload.response.data : payload
    }
  },
  persist: false
})
