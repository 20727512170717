import { FFmpeg } from "@/ffmpeg_core/core/esm";
import { fetchFile, toBlobURL } from "@/ffmpeg_core/utils/esm";
import { ref } from "vue";

export function useFFmpeg() {
	const tempFileOutputName = `${Date.now()}.mp4`;
	const isReady = ref(false);
	const isConverting =  ref(true);
	const message = ref("Initialize");
	const output = ref("null");
	const fileToUpload = ref(null as unknown as File);
	const isSupported = ref(true);

	const ffmpeg = new FFmpeg();

	const convertToGIF = async (video: File) => {
    await ffmpeg.load({
      coreURL: await toBlobURL('/ffmpeg_core/ffmpeg-core.js', "text/javascript"),
      wasmURL:  await toBlobURL('/ffmpeg_core/ffmpeg-core.wasm',
        "application/wasm",)
    });
		await ffmpeg.writeFile(video.name, await fetchFile(video));
    await ffmpeg.exec(["-i", video.name, tempFileOutputName]);
    const data = await ffmpeg.readFile(tempFileOutputName);
		output.value = URL.createObjectURL(new Blob([data]));
    fileToUpload.value = new File([new Blob([data])], tempFileOutputName, {
      type: "video/mp4",
    });

    message.value = "Complete transcoding";
    isReady.value=true
		isConverting.value = false;
  }

   return {
		isReady,
		convertToGIF,
		output,
		isConverting,
		isSupported,
		message,
    ffmpeg,
    fileToUpload
	};
}
