<template>
  <form class="form">
    <div class="my-3">
      <IInput
        :id="'email'"
        v-model.trim="email"
        :label="'Email'"
        :input-type="'email'"
        :placeholder="'Your email'"
        :tab-index="1"
        :is-required="true"
        :klass="[
          getError &&
            (getError.input_name === 'email' || getError.input_name === undefined) &&
            getError.status,
        ]"
        @focusout="verifyEmail"
      />
    </div>
    <div class="my-3">
      <IInput
        :id="'password'"
        v-model.trim="password"
        :label="'Password'"
        :input-type="'password'"
        :placeholder="'Your password'"
        :tab-index="2"
        :is-required="true"
        :klass="[
          'password',
          getError &&
            (getError.input_name === 'password' || getError.input_name === undefined) &&
            getError.status,
        ]"
      />
    </div>
    <IInput
      v-if="socketID"
      :id="'socketID'"
      v-model.trim="socketID"
      :is-readonly="true"
      :label="'socketID'"
      :input-type="'text'"
      :placeholder="'socketID'"
      :tab-index="200"
    />
  </form>
</template>

<script lang="ts">
import { Input as IInput } from "@/components";
import { validateEmail } from "@/helpers";
import { useAuthTokenStore } from "@/store/authToken";
import { useErrorStore } from "@/store/errorStore";
import { watchWithFilter } from "@vueuse/core";
import { mapState, mapStores } from "pinia";
import { computed, defineComponent, ref } from "vue";
import { event } from "vue-gtag";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "UserLoginForm",
  components: {
    IInput,
  },
  emits: ["e-email", "e-password", "canSubmitted"],
  setup(
    _,
    {
      emit,
    }: {
      emit: (
        event: "e-email" | "e-password" | "canSubmitted",
        value: string | boolean
      ) => void;
    }
  ) {
    event("login", { method: "Google" });
    const router = useRouter();

    // Define needed instances.
    const email = ref("");
    const password = ref("");
    const socketID = computed(() => {
      const id = router.currentRoute.value.query.socketId;
      return Array.isArray(id) ? id[0] : id;
    });

    watchWithFilter(password, () => {
      if (password.value === "") {
        emit("canSubmitted", false);
      } else {
        emit("e-password", password.value);
        emit("canSubmitted", true);
      }
    });

    const verifyEmail = () => {
      if (!email.value) return;

      if (validateEmail(email.value)) {
        useErrorStore().$reset();

        emit("e-email", email.value);
      } else {
        useErrorStore().UPDATE_ERROR({
          error: "Invalid Email",
          status: "error",
          input_name: "email",
        });
      }
    };

    return {
      email,
      password,
      verifyEmail,
      socketID,
    };
  },
  computed: {
    ...mapState(useErrorStore, {
      getError: "errors",
    }),
    ...mapState(useAuthTokenStore, {
      isRequestLoading: "isRequestLoading",
      isLoading: "isLoading",
    }),
    ...mapStores(useErrorStore),
  },
  watch: {},
});
</script>
